import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  CustomBreadcrumbs,
  Switch,
  TextField,
  FormControl,
} from 'src/components/ui';
import Typography from '@mui/material/Typography';

import {
  useEditContent,
  useGetContent,
  useGetContentCategories,
  useEditCategories,
} from 'src/components/hooks';

import { ContentDrawer } from './ContentDrawer';

export const ContentTable = () => {
  const { data, getContentCategories } = useGetContentCategories();
  const { setIsDrawerOpen } = useEditContent();
  const { getContent } = useGetContent();
  const [contentId, setContentId] = useState('');
  const { editCategories, isCategoriesEdited } = useEditCategories();

  useEffect(() => {
    getContentCategories();
  }, [getContentCategories, isCategoriesEdited]);

  const handleContent = (item) => {
    getContent(item.id);
    setContentId(item.id);
    setIsDrawerOpen(true);
  };

  const handleStatusChange = (e, item) => {
    e.stopPropagation();

    editCategories(
      {
        // ...item,
        active: !item?.active,
      },
      item?.id
    );
  };

  return (
    <Box>
      <Helmet>
        <title>Content List</title>
      </Helmet>
      <Container
        sx={{
          width: {
            xs: '100%',
            sm: 1200,
          },
        }}
      >
        <CustomBreadcrumbs
          heading="Content"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        {data ? (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '16px',
            }}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: '144px',
                  height: '164px',
                  borderRadius: '14px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  p: 2,
                  cursor: 'pointer',
                }}
                onClick={() => handleContent(item)}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ marginRight: '8px' }}>
                    Status: {item?.active}
                  </Typography>
                  <Switch
                    checked={item?.active} // Switch reflects the status state
                    onClick={(event) => event.stopPropagation()} // Stop propagation when clicking on the Switch
                    onChange={(event) => handleStatusChange(event, item)} // Toggle status without opening drawer
                  />
                </Box>
                <Typography
                  sx={{ textTransform: 'capitalize' }}
                  variant="h4"
                  component="div"
                >
                  {item?.type}
                </Typography>
                <Typography sx={{ marginTop: '6px' }} variant="body1" component="div">
                  {item?.title}
                </Typography>
                <Typography sx={{ marginTop: '6px' }} variant="body1" component="div">
                  {item?.position !== null && `Position -${item?.position}`}
                </Typography>

                <Box>
                  {/* <Button
                    style={{
                      color: 'white',
                      backgroundColor: '#00a66e',
                      height: '30px',
                      width: '110px',
                      marginTop: '12px',
                    }}
                    // onClick={handleAction}
                  >
                    Position: {item.position}
                  </Button> */}
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="h6">Loading...</Typography>
        )}
      </Container>
      <ContentDrawer contentId={contentId} />
    </Box>
  );
};
