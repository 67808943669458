import { create } from 'zustand';
import axios from 'axios';
import axiosInstance from '../auth/axiosInstance';

export const useGetContentCategories = create((set) => ({
  data: [],
  isDataFetched: false,
  dataErrorMessage: '',

  getContentCategories: () => {
    set((state) => ({
      ...state,
      data: [],
      isDataFetching: true,
      dataErrorMessage: '',
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/categories/admin`)
      .then((res) => {
        set((state) => ({
          ...state,
          data: res.data,
          isDataFetched: false,
          dataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          data: [],
          isDataFetched: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
