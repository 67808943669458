import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const getRequest = (set, id) => {
  set((state) => ({
    ...state,
    data: null,
    isDataFetched: false,
    dataErrorMessage: '',
  }));

  axiosInstance
    .get(`${process.env.REACT_APP_API_BASE_URL}/requests/${id}/admin`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
      },
    })
    .then((res) => {
      set((state) => ({
        ...state,
        data: res.data,
        isDataFetched: true,
        dataErrorMessage: '',
      }));
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        data: null,
        isDataFetched: false,
        dataErrorMessage: error.message,
      }));
    });
};

export const useGetRequest = create((set) => ({
  data: null,
  isDataFetched: false,
  dataErrorMessage: '',
  getRequest: (id) => getRequest(set, id),
}));
