import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../auth/axiosInstance';

export const useGetInventories = create((set) => ({
  data: [],
  isDataFetched: false,
  dataErrorMessage: '',

  getInventories: (page, limit, searchObject) => {
    set((state) => ({
      ...state,
      isDataFetched: false,
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/inventory`, {
        params: {
          page: page + 1,
          limit,
          orderBy: 'created_at',
          ...searchObject,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          data: res.data,
          isDataFetched: true,
          dataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          data: [],
          isDataFetched: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
