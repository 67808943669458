import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../../auth/axiosInstance';

export const useAddInventory = create((set) => ({
  isInventoryAdded: false,
  dataErrorMessage: '',

  addInventory: (body, type) => {
    set((state) => ({
      ...state,
      isInventoryAdded: false,
    }));

    let endpoint = '';
    if (type === 'membership') endpoint = 'plans';
    if (type === 'resource') endpoint = 'resources';
    if (type === 'product') endpoint = 'products';

    axiosInstance
      .post(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, body)
      .then((res) => {
        set((state) => ({
          ...state,
          isInventoryAdded: true,
          dataErrorMessage: '',
        }));
        enqueueSnackbar(`Inventory created successfuly`, { variant: 'success' });
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isInventoryAdded: false,
          dataErrorMessage: error.message,
        }));
        enqueueSnackbar(`Inventory can't be created`, { variant: 'error' });
      });
  },
}));
