import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../auth/axiosInstance';

export const useRequestActions = create((set) => ({
  isRequestDeleted: false,
  isStatusChanged: false,
  dataErrorMessage: '',

  setRequestStatus: (id, actionValue) => {
    set((state) => ({
      ...state,
      isStatusChanged: false,
      dataErrorMessage: '',
    }));

    axiosInstance
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/requests/${id}/admin/${actionValue}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          },
        }
      )
      .then(() => {
        set((state) => ({
          ...state,
          isStatusChanged: true,
          dataErrorMessage: '',
        }));
        // enqueueSnackbar('Status updated successfully', { variant: 'success' });
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isStatusChanged: false,
          dataErrorMessage: error.message,
        }));
        enqueueSnackbar('Status cant be updated', { variant: 'error' });
      });
  },

  deleteRequest: (id) => {
    set((state) => ({
      ...state,
      isRequestDeleted: false,
    }));

    axiosInstance
      .delete(`${process.env.REACT_APP_API_BASE_URL}/requests/admin/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then(() => {
        set((state) => ({
          ...state,
          isStatusChanged: true,
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isStatusChanged: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
