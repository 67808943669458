import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../../auth/axiosInstance';

export const useDeleteInventory = create((set) => ({
  isInventoryDeleted: false,
  dataErrorMessage: '',

  deleteInventory: (id, type) => {
    set((state) => ({
      ...state,
      isInventoryDeleted: false,
    }));

    let endpoint = '';
    if (type === 'plan') endpoint = 'plans';
    if (type === 'product') endpoint = 'products';
    if (type === 'resource') endpoint = 'resources';

    axiosInstance
      .delete(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}/${id}`)
      .then((res) => {
        set((state) => ({
          ...state,
          isInventoryDeleted: true,
          dataErrorMessage: '',
        }));
        enqueueSnackbar(`Inventory deleted successfuly`, { variant: 'success' });
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isInventoryDeleted: false,
          dataErrorMessage: error.message,
        }));
        enqueueSnackbar(`Inventory can't be deleted`, { variant: 'error' });
      });
  },
}));
