// upsale by type
export const filterByType = (contentData, upsellType) => {
  let type = '';
  if (upsellType === 'membership') type = 'plan';
  if (upsellType === 'product') type = 'product';
  if (upsellType === 'resource') type = 'resource;';

  const filteredArray = contentData?.filter((item) => item?.type === type);
  // const uniqueCategoryTitles = [...new Set(filteredArray?.map((item) => item.title))];
  return filteredArray;
};

// upsale by names
export const filterByNames = (array, category) => {
  const filteredArray = array?.filter((item) => item?.title === category);
  return filteredArray[0]?.inventory;
};
// --------------// --------------// --------------// --------------// --------------// --------------// --------------

// stock tautol
export const filteredTautology = (array, type) => {
  if (array?.length <= 1) {
    return array;
  }

  let category = '';
  if (type === 'membership') category = 'GroupName';
  if (type === 'product') category = 'Tags';
  if (type === 'resource') category = 'GroupName';

  return array?.filter(
    (item, index, self) =>
      self.findIndex((innerItem) => innerItem[category] === item[category]) === index
  );
};

export const inventoryUrlSetter = (id) => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set('inventoryId', id);
  window.history.replaceState(null, '', newUrl.toString());
};

export const inventoryUrlRemover = () => {
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.delete('inventoryId');
  window.history?.replaceState(null, '', newUrl.toString());
};

export const dateTransform = (date) => {
  const dateObj = new Date(date);
  return dateObj.toLocaleString().slice(0, 10);
};
