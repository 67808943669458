import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useAddInventory,
  useEditInventory,
  useGetInventory,
  useGetNexudusData,
  useGetNexudusResourceData,
  useGetToken,
  useInventoryUrl,
  useUpsaleData,
} from 'src/components/hooks';
import { CloseIcon } from 'src/components/assets/icons';
import { useFormik } from 'formik';

import {
  Drawer,
  Box,
  IconButton,
  Typography,
  TabContext,
  TabList,
  Tab,
  TabPanel,
} from 'src/components/ui';

import { RuleTab, StockTab, UpsaleTab } from './Tabs';
import { inventoryUrlRemover, inventoryUrlSetter } from './Tabs/utils/utils';

export const InventoryDrawer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inventoryId = queryParams.get('inventoryId');

  const [value, setValue] = useState('1');
  const { upsaleData, setUpsaleData } = useUpsaleData();
  const { getInventory, data: InventoryData } = useGetInventory();
  const { editInventory, actionType, setIsDrawerOpen, id, isDrawerOpen } =
    useEditInventory();
  const [resourceTag, setResourceTag] = '';
  // const { inventoryUrl, setInventoryUrl } = useInventoryUrl();

  // nexudus
  const { getCategoryData, getNameData } = useGetNexudusData();
  const { getResourceNames } = useGetNexudusResourceData();
  // const { sessionToken } = useGetToken();

  // inventory
  const { addInventory } = useAddInventory();

  // const validationSchema = Yup.object().shape({
  //   name: Yup.string().min(3, 'Name must be at least 3 characters').required('*Required'),
  //   category: Yup.string().required('*Required'),
  // });

  const formik = useFormik({
    initialValues: {
      type: '',
      category: '',
      name: '',
      nexudus_id: null,
      price: 20,
      is_daily: false,
      start_date: null,
      end_date: null,
      stock: 1,
      // stock_left: 1,
      description: 'default',
      upsell_items: [],
    },
    // validationSchema,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (e) => {
      const formattedValues = {
        ...e,
        upsell_items: upsaleData.map((item) => item?.upsaleId || item?.id),
      };

      if (actionType === 'add') {
        addInventory(formattedValues, type);
      }

      if (actionType === 'edit') {
        editInventory(formattedValues, id, type);
      }
      handleOpenedDrawer();
    },
  });
  const { type, category } = formik.values;

  // useEffect(() => {
  //   setIsDrawerOpen(false);
  //   formik.resetForm();
  // }, [sessionToken]);

  useEffect(() => {
    if (!type) return;
    getCategoryData(type, resourceTag);
  }, [type]);

  useEffect(() => {
    if (!category) return;
    if (type === 'membership' || type === 'product') getNameData(type, category);
    if (type === 'resource') getResourceNames(formik.values.category);
  }, [category]);

  // getEdit
  useEffect(() => {
    if (!id && !inventoryId) return;
    const anyId = !id ? inventoryId : id;
    getInventory(anyId);
    setIsDrawerOpen(true, 'edit', id);
  }, [id, inventoryId]);

  // setEdit
  useEffect(() => {
    if (!InventoryData) return;
    if (InventoryData) {
      const typeFormik =
        // eslint-disable-next-line no-nested-ternary
        InventoryData?.type === 'plan'
          ? 'membership'
          : InventoryData?.type === 'product'
          ? 'product'
          : 'resource';

      const updatedInventoryData = {
        ...InventoryData,
        type: typeFormik,
        category: InventoryData.category?.title,
        name: InventoryData?.name,
      };
      // to show upsales
      setUpsaleData(InventoryData.upsell_items);
      formik.setValues(updatedInventoryData);
    }

    if (!id) return;
    inventoryUrlSetter(id);
  }, [id, InventoryData]);

  const handleOpenedDrawer = () => {
    setIsDrawerOpen(false);

    setTimeout(() => {
      formik.resetForm();
      inventoryUrlRemover();
      setUpsaleData([]);
    }, 0);
  };

  if (!isDrawerOpen) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleOpenedDrawer}
      PaperProps={{
        sx: {
          width: value === '2' ? '50%' : '30%',
          '@media (max-width: 600px)': {
            width: '70%',
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
        }}
      >
        <Typography variant="h6">
          {actionType === 'add' ? 'Add Inventory' : 'Edit Inventory'}
        </Typography>
        <IconButton onClick={handleOpenedDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ padding: '24px' }}>
        <TabContext value={value} sx={{ typography: 'body1' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              aria-label="lab API tabs example"
            >
              <Tab label="Stock" value="1" />
              {/* <Tab label="Upsale" value="2" />
              <Tab label="Rules" value="3" /> */}
            </TabList>
          </Box>

          {/* Stock */}
          <TabPanel value="1">
            <StockTab id={id} formik={formik} setResourceTag={setResourceTag} />
          </TabPanel>

          {/* Upsale  */}

          {/* <TabPanel value="2">
            <UpsaleTab id={id} parentForm={formik} setValue={setValue} />
          </TabPanel> */}

          {/* Rules  */}
          {/*   
           <TabPanel value="3">
            <RuleTab id={id} />
          </TabPanel>
          */}
        </TabContext>
      </Box>
    </Drawer>
  );
};
