import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const getContent = (set, id) => {
  set((state) => ({
    ...state,
    isDataFetched: false,
    dataErrorMessage: '',
  }));

  axiosInstance
    .get(`${process.env.REACT_APP_API_BASE_URL}/categories/${id}/content`)
    .then((res) => {
      set((state) => ({
        ...state,
        data: res.data,
        isDataFetched: true,
        dataErrorMessage: '',
      }));
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        isDataFetched: false,
        dataErrorMessage: error.message,
      }));
    });
};

export const useGetContent = create((set) => ({
  isDataFetched: false,
  dataErrorMessage: '',
  data: '',
  getContent: (body, id) => getContent(set, body, id),
}));
