import axios from 'axios';
import { useNavigate } from 'react-router';
import { setRefreshToken } from './useGetToken';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const refreshToken = localStorage.getItem('refreshToken');

        const response = await axios.post(
          'https://sso.staging.tcenter.cloud/v1/auth/token/refresh',
          { refresh_token: refreshToken },
          {
            headers: {
              'x-public-key': '2817d1b3-d059-4cef-9b0c-4d40931871b5',
            },
          }
        );

        localStorage.setItem('sessionToken', response.data.access_token);
        setRefreshToken(response.data.access_token);

        error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
        return axiosInstance(error.config);
      } catch (errorData) {
        console.error('Token refresh failed', errorData);
        window.location.href = '/';
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
