import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { create } from 'zustand';

export const useCreateAccount = create((set) => ({
  isAccCreated: false,
  dataErrorMessage: '',

  registerAccount: (body) => {
    set((state) => ({
      ...state,
      isAccCreated: false,
      dataErrorMessage: '',
    }));

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/auth/register/admin`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then(() => {
        set((state) => ({
          ...state,
          isAccCreated: true,
          dataErrorMessage: '',
        }));
        enqueueSnackbar(`Admin account was created successfuly`, { variant: 'success' });
      })
      .catch((error) => {
        console.log(error.response.status);
        set((state) => ({
          ...state,
          isAccCreated: false,
          dataErrorMessage: error.message,
        }));
        enqueueSnackbar(`Admin was not created`, { variant: 'error' });
      });
  },
}));
