import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../auth/axiosInstance';

export const editRequest = (set, body, id) => {
  set((state) => ({
    ...state,
    isRequestEdited: false,
    dataErrorMessage: '',
  }));

  axiosInstance
    .patch(`${process.env.REACT_APP_API_BASE_URL}/requests/${id}/admin`, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
      },
    })
    .then(() => {
      set((state) => ({
        ...state,
        isRequestEdited: true,
        dataErrorMessage: '',
      }));
      enqueueSnackbar('Request was updated successfuly', { variant: 'success' });
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        isRequestEdited: false,
        dataErrorMessage: error.message,
      }));
      enqueueSnackbar('Request can not be updated', { variant: 'error' });
    });
};

export const useEditRequest = create((set) => ({
  isRequestEdited: false,
  dataErrorMessage: '',
  editRequest: (body, id) => editRequest(set, body, id),
  // drawer
  id: null,
  isDrawerOpen: false,
  setIsDrawerOpen: (value, id) =>
    set((state) => ({
      ...state,
      isDrawerOpen: value,
      id,
    })),
}));
