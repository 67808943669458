import Sidebar from 'src/components/ui/sidebar';
import { ContentTable } from 'src/features/Tables';

export const ContentPage = () => {
  return (
    <Sidebar>
      <ContentTable />
    </Sidebar>
  );
};
