import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useEditRequest,
  useGetRequests,
  useGetToken,
  useRequestActions,
} from 'src/components/hooks';
import { useSpinner } from 'src/components/hooks/useExtraStore';

import {
  Container,
  TableNoData,
  useTable,
  Card,
  Scrollbar,
  CustomBreadcrumbs,
  Box,
} from 'src/components/ui';

import { RequestsHead, RequestsRow, RequestsPagination } from './ui';
import { RequestDrawer } from './ui/RequestDrawer';

const STATUS_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'IN_REVIEW', label: 'Pending' },
  { value: 'rejected', label: 'Rejected' },
];

export const RequestsTable = () => {
  const { getRequests, data } = useGetRequests();
  const { isStatusChanged, isRequestDeleted } = useRequestActions();
  const { setSpinner } = useSpinner();

  const table = useTable();

  useEffect(() => {
    setSpinner(true);
    getRequests(table.page, table.rowsPerPage);
  }, [table.page, table.rowsPerPage, isStatusChanged, isRequestDeleted]);

  return (
    <>
      <Helmet>
        <title>Request List</title>
      </Helmet>
      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Requests List"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Scrollbar>
            <RequestsHead />
            {data.items?.map((row) => (
              <RequestsRow key={row.id} row={row} />
            ))}

            <TableNoData notFound={!data.items?.length} />
          </Scrollbar>

          <RequestsPagination
            count={data.meta?.totalItems}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
          <RequestDrawer />
        </Card>
      </Container>
    </>
  );
};
