import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import BrandLogo from 'src/components/assets/icons/brand.png';
import WhiteBrandLogo from 'src/components/assets/icons/whiteBrand.png';

// routes
// import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const dblockLogo = theme.palette.mode === 'dark' ? WhiteBrandLogo : BrandLogo;

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        display: 'inline-flex',
        ...sx,
        marginTop: theme.palette.mode === 'dark' ? '40px' : '20px',
      }}
    >
      <img src={dblockLogo} alt="logo" />
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
    // component={RouterLink} href="/" sx={{ display: 'contents' }}
    >
      <Box sx={{ marginBottom: '50px' }}>{logo}</Box>
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
