import { enqueueSnackbar } from 'notistack';
import { formatDate } from 'src/components/utils/format-time';
import { toExcel } from 'to-excel';

// Set headers
const headers = [
  { label: 'Nexudus User ID', field: 'account.nexudus_id' },
  { label: 'Item Amount', field: 'item_amount' },
  { label: 'Requested Date', field: 'created_at' },
  { label: 'Status', field: 'status' },
  { label: 'Last seen', field: 'last_seen' },
  { label: 'Requested Item', field: 'inventory.name' },
  { label: 'Purchased Item', field: 'inventory.name' },
  { label: 'Purchase Date', field: 'purchase_date' },
];

// Function to trigger the download
export const DownloadExcel = (myList) => {
  // Format the date fields before exporting
  const formattedList = myList.map((row) => ({
    ...row,
    created_at: formatDate(row?.created_at),
    last_seen: formatDate(row?.last_seen),
    purchase_date: formatDate(row?.purchase_date),
  }));

  // Generate Excel file content with the formatted dates
  const content = toExcel.exportXLS(headers, formattedList, 'filename');

  // Create a Blob from the content
  const blob = new Blob([content], { type: 'application/vnd.ms-excel' });

  // Create an anchor element and trigger a download
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'filename.xls';

  // Append to the DOM to ensure it works in Firefox
  document.body.appendChild(a);

  // Trigger the download
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);

  // Show a success message
  enqueueSnackbar('Requests exported successfully', { variant: 'success' });
};
