import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const editContent = (set, body, id) => {
  set((state) => ({
    ...state,
    isContentEdited: false,
    dataErrorMessage: '',
  }));

  axiosInstance
    .post(`${process.env.REACT_APP_API_BASE_URL}/categories/${id}/content`, body)
    .then(() => {
      set((state) => ({
        ...state,
        isContentEdited: true,
        dataErrorMessage: '',
      }));
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        isContentEdited: false,
        dataErrorMessage: error.message,
      }));
    });
};

export const useEditContent = create((set) => ({
  isContentEdited: false,
  dataErrorMessage: '',
  editContent: (body, id, contentId) => editContent(set, body, id, contentId),
  // drawer
  id: null,
  isDrawerOpen: false,
  actionType: 'add',
  setIsDrawerOpen: (value, actionType, id) =>
    set((state) => ({
      ...state,
      isDrawerOpen: value,
      actionType,
      id,
    })),
}));
