import Sidebar from 'src/components/ui/sidebar';
import { RequestsTable } from 'src/features/Tables';

export const RequestsPage = () => {
  return (
    <Sidebar>
      <RequestsTable />
    </Sidebar>
  );
};
