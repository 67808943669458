import { create } from 'zustand';

export const useUpsaleData = create((set) => ({
  upsaleData: [],
  setUpsaleData: (data) => set({ upsaleData: data }),
}));

export const useInventoryUrl = create((set) => ({
  inventoryUrl: '',
  setInventoryUrl: (data) => set({ inventoryUrl: data }),
}));

export const useSpinner = create((set) => ({
  spinner: false,
  setSpinner: (value) => set({ spinner: value }),
}));
