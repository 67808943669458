import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const useGetNexudusUser = create((set) => ({
  userData: null,
  isDataFetched: false,
  dataErrorMessage: '',

  getNexudusUserData: (id) => {
    set((state) => ({
      ...state,
      userData: null,
      isNexudusUserFetched: false,
      dataErrorMessage: '',
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/nexudus-admin/coworkers/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          userData: res.data,
          isNexudusUserFetched: true,
          dataErrorMessage: '',
        }));
      })
      .catch((error) => {
        set((state) => ({
          ...state,
          userData: null,
          isNexudusUserFetched: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
