import { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSpinner } from 'src/components/hooks/useExtraStore';

export function Spinner() {
  const { spinner, setSpinner } = useSpinner();

  useEffect(() => {
    setTimeout(() => {
      setSpinner(false);
    }, 1000);
  }, [spinner]);

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
        }}
        open={spinner}
      >
        <CircularProgress size={120} color="inherit" />
      </Backdrop>
    </div>
  );
}
