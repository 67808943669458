import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const getInventory = (set, id) => {
  set((state) => ({
    ...state,
    data: null,
    isInventoryFetched: false,
    dataErrorMessage: '',
  }));

  axiosInstance
    .get(`${process.env.REACT_APP_API_BASE_URL}/inventory/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
      },
    })
    .then((res) => {
      set((state) => ({
        ...state,
        data: res.data,
        isInventoryFetched: true,
        dataErrorMessage: '',
      }));
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        data: null,
        isInventoryFetched: false,
        dataErrorMessage: error.message,
      }));
    });
};

export const useGetInventory = create((set) => ({
  data: null,
  isInventoryFetched: false,
  dataErrorMessage: '',
  getInventory: (id) => getInventory(set, id),
}));
