import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'src/components/hooks/auth/axiosInstance';
import { Label, Box, TablePagination, Button } from 'src/components/ui';
import { useGetRequests } from 'src/components/hooks';
import { DownloadExcel } from './utils/DownloadExcel';

export const RequestsPagination = ({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  setTableData,
  handleSearch,
  type,
  ...other
}) => {
  const [data, setData] = useState([]);

  const handleClick = (e) => {
    e.stopPropagation();
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/requests/admin`, {
        params: {
          page: 1,
          limit: 10000,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        console.log(res.data.items);
        setData(res.data.items);
      });
  };

  useEffect(() => {
    if (!data.length) return;
    DownloadExcel(data);
  }, [data]);

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Label
        sx={{
          position: 'relative',
          width: '70px',
          top: '15px',
          height: '30px',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        Reset
      </Label>

      <Button
        sx={{
          position: 'relative',
          width: '100px',
          top: '15px',
          height: '30px',
          marginLeft: '20px',
          cursor: 'pointer',
          background: '#1224FF',
          color: 'white',
        }}
        variant="contained"
        onClick={handleClick}
      >
        Export
      </Button>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
        sx={{
          borderTopColor: 'transparent',
        }}
      />
    </Box>
  );
};

RequestsPagination.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
  setTableData: PropTypes.func,
  handleSearch: PropTypes.func,
  type: PropTypes.string,
  myList: PropTypes.object,
};
