import { useState } from 'react';
import { fDate } from 'src/components/utils/format-time';
import { FormControl, OutlinedInput, Popover } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useDeleteInventory, useEditInventory } from 'src/components/hooks';

import {
  TableRow,
  TableCell,
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
  InputLabel,
  Label,
  ConfirmDialog,
  Typography,
} from 'src/components/ui';
import { DeleteIcon } from 'src/components/assets/icons';

export const InventoryRow = ({ row }) => {
  const { deleteInventory } = useDeleteInventory();
  const { editInventory, setIsDrawerOpen, isDrawerOpen } = useEditInventory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionValue, setActionValue] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleAction = () => {
    if (actionValue === 'Active' || actionValue === 'Disabled') {
      editInventory({ status: actionValue }, row.id, row.type);
    }

    setAnchorEl(false);
  };

  const handleRow = (e) => {
    if (isDeleteOpen) return;
    if (!isDrawerOpen && !anchorEl) {
      setIsDrawerOpen(true, 'edit', row.id);
    }
  };

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleRow}>
      <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
        {row.type}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.category?.title || 'N/A'}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.name}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.availability_start_date
          ? `${row.availability_start_date} / ${row.availability_end_date}`
          : 'Daily'}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.stock ? `${row.stock_left} / ${row.stock}` : 'Unlimited'}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Label
          sx={{ width: '70px' }}
          color={
            (row.status === 'Active' && 'success') ||
            (row.status === 'Disabled' && 'error') ||
            // (status === 'REJECTED' && 'error') ||
            'default'
          }
        >
          {row.status}
        </Label>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap', display: 'flex' }}>
        <IconButton>
          <SettingsIcon
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            sx={{ color: '#1224FF' }}
          />
        </IconButton>

        <IconButton>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteOpen(true);
            }}
            sx={{ color: '#F61909' }}
          />
        </IconButton>

        <ConfirmDialog
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(false)}
          apply={() => deleteInventory(row.id, row.category?.type || row?.type)}
          content={
            <Box>
              <Typography>Delete Account</Typography>
            </Box>
          }
        />

        <Popover
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
          }}
        >
          <Box
            sx={{
              borderRadius: '14px',
              p: 2,
            }}
          >
            <FormControl sx={{ width: '200px' }}>
              <InputLabel id="name-label">Actions</InputLabel>
              <Select
                value={actionValue}
                onChange={(e) => {
                  setActionValue(e.target.value);
                }}
                fullWidth
                input={<OutlinedInput label="Actions" />}
              >
                <MenuItem value="Active">Activate</MenuItem>
                <MenuItem value="Disabled">Disable</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Buttons */}
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              marginLeft: '20px',
              marginBottom: '10px',
            }}
          >
            <Button
              style={{
                color: 'white',
                backgroundColor: '#00a66e',
                height: '30px',
                width: '90px',
              }}
              onClick={handleAction}
            >
              Apply
            </Button>
            <Button
              style={{
                color: 'white',
                backgroundColor: '#FF5530',
                height: '30px',
                width: '90px',
              }}
              onClick={() => setAnchorEl(null)}
            >
              Cancel
            </Button>
          </Box>
        </Popover>
      </TableCell>
    </TableRow>
  );
};

InventoryRow.propTypes = {
  row: PropTypes.object,
};
