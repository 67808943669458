import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../../auth/axiosInstance';

export const useEditInventory = create((set) => ({
  isInventoryEdited: false,
  dataErrorMessage: '',

  editInventory: (body, id, type) => {
    let endpoint = '';
    if (type === 'plan' || type === 'membership') endpoint = 'plans';
    if (type === 'product') endpoint = 'products';
    if (type === 'resource') endpoint = 'resources';

    set((state) => ({
      ...state,
      isInventoryEdited: false,
      dataErrorMessage: '',
    }));

    console.log(endpoint, id);

    axiosInstance
      .patch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}/${id}`, body)
      .then(() => {
        set((state) => ({
          ...state,
          isInventoryEdited: true,
          dataErrorMessage: '',
        }));
        enqueueSnackbar(`Inventory edited successfuly`, { variant: 'success' });
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isInventoryEdited: false,
          dataErrorMessage: error.message,
        }));
        enqueueSnackbar(`Inventory can't be edited`, { variant: 'error' });
      });
  },

  // drawer
  id: null,
  isDrawerOpen: false,
  actionType: 'add',
  setIsDrawerOpen: (value, actionType, id) =>
    set((state) => ({
      ...state,
      isDrawerOpen: value,
      actionType,
      id,
    })),
}));
