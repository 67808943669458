import 'src/components/utils/locales/i18n';
import 'simplebar-react/dist/simplebar.min.css';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MotionLazy from 'src/components/ui/animate/motion-lazy';
import SnackbarProvider from 'src/components/ui/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/ui/settings';
import { ThemeProvider } from 'src/components/ui/theme';
import { AppRouter } from './pages/AppRouter';

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light',
          themeLayout: 'vertical', 
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <SettingsDrawer />
              <AppRouter />
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
