// @mui
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, FormControl, TextField } from '@mui/material';
import { Scrollbar } from 'src/components/ui/scrollbar';
import { useCreateAccount } from 'src/components/hooks';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import { useFormik } from 'formik';
// theme
import { paper } from 'src/components/ui/theme/css';

import Iconify from '../../iconify';
import { useSettingsContext } from '../context';
import BaseOptions from './base-option';
import { ConfirmDialog } from '../../custom-dialog';

export default function SettingsDrawer() {
  const theme = useTheme();
  const settings = useSettingsContext();

  const labelStyles = {
    mb: 1.5,
    color: 'text.disabled',
    fontWeight: 'fontWeightSemiBold',
  };

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Settings
      </Typography>

      <IconButton onClick={settings.onClose}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  const renderMode = (
    <div>
      <Typography variant="caption" component="div" sx={{ ...labelStyles }}>
        Theme
      </Typography>

      <BaseOptions
        value={settings.themeMode}
        onChange={(newValue) => settings.onUpdate('themeMode', newValue)}
        options={['light', 'dark']}
        icons={['sun', 'moon']}
      />
    </div>
  );
  // ----------
  const [isOpen, setIsOpen] = useState(false);
  const { registerAccount } = useCreateAccount();

  const formik = useFormik({
    initialValues: {
      email: '',
      username: '',
      password: '',
      firstName: '',
      lastName: '',
    },
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (values) => {
      console.log(values);
      registerAccount(values);
      formik.resetForm();
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={settings.open}
      onClose={settings.onClose}
      slotProps={{
        backdrop: { invisible: true },
      }}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          ...paper({ theme, bgcolor: theme.palette.background.default }),
          width: 280,
        },
      }}
    >
      {renderHead}
      <Divider sx={{ borderStyle: 'dashed' }} />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          {renderMode}
          <Button
            sx={{ background: '#1224FF', color: 'white' }}
            variant="outlined"
            color="inherit"
            onClick={() => setIsOpen(true)}
          >
            Create Account
          </Button>

          <ConfirmDialog
            open={isOpen}
            onClose={handleClose}
            apply={formik.handleSubmit}
            content={
              <Box>
                <Typography>Create Account</Typography>
                {/* type */}
                {/* <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="email"
                    label="Email"
                    value={formik.values.email}
                      onChange={(e) => {
                      const lowercaseUsername = e.target.value.toLowerCase();
                      formik.setFieldValue('username', lowercaseUsername);
                    }}
                  />
                </FormControl> */}

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={(e) => {
                      const lowercaseUsername = e.target.value.toLowerCase();
                      formik.setFieldValue('username', lowercaseUsername);
                      formik.setFieldValue('email', lowercaseUsername);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="password"
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="firstName"
                    label="Firstname"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginTop: '24px' }}>
                  <TextField
                    name="lastName"
                    label="LastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Box>
            }
          />
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
