import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEditInventory, useGetInventories, useSync } from 'src/components/hooks';

import { Box, TablePagination, Button, Label } from 'src/components/ui';

export const InventoryPagination = ({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10],
  sx,
  setTableData,
  handleSearch,
  type,
  ...other
}) => {
  const { setIsDrawerOpen } = useEditInventory();
  const { sync, data } = useSync();
  const { enqueueSnackbar } = useSnackbar();

  const { getInventories } = useGetInventories();

  const handleSync = () => {
    sync();
    enqueueSnackbar(`${data}`, { variant: 'success' });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Button
        sx={{
          position: 'relative',
          width: '70px',
          top: '15px',
          height: '30px',
          marginLeft: '20px',
          cursor: 'pointer',
          background: '#1224FF',
          color: 'white',
        }}
        variant="contained"
        onClick={() => setIsDrawerOpen(true, 'add')}
      >
        Add
      </Button>

      {/* <Label
        sx={{
          position: 'relative',
          width: '70px',
          top: '15px',
          height: '30px',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
        onClick={handleSync}
      >
        Sync
      </Label> */}

      <Label
        sx={{
          position: 'relative',
          width: '70px',
          top: '15px',
          height: '30px',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
        onClick={() => getInventories(0, 5)}
      >
        Reset
      </Label>

      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
        sx={{
          borderTopColor: 'transparent',
        }}
      />
    </Box>
  );
};

InventoryPagination.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
  setTableData: PropTypes.func,
  handleSearch: PropTypes.func,
  type: PropTypes.string,
};
