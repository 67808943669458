import Sidebar from 'src/components/ui/sidebar';
import { InventoryTable } from 'src/features/Tables';

export const InventoryPage = () => {
  return (
    <Sidebar>
      <InventoryTable />
    </Sidebar>
  );
};
