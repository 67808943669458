import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const useGetNexudusData = create((set) => ({
  categoryData: [],
  nameData: [],
  isCategoryDataFetched: false,
  categoryDataErrorMessage: '',

  getCategoryData: (type, setResourceTag) => {
    set((state) => ({
      ...state,
      categoryData: [],
      isCategoryDataFetched: true,
      categoryDataErrorMessage: '',
    }));

    let endpoint = '';
    if (type === 'membership') endpoint = 'plans?size=150';
    if (type === 'product') endpoint = 'products';

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/nexudus-admin/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          categoryData: res.data,
          isCategoryDataFetched: false,
          categoryDataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          categoryData: [],
          isCategoryDataFetched: false,
          categoryDataErrorMessage: error.message,
        }));
      });
  },

  getNameData: (type, category) => {
    set((state) => ({
      ...state,
      nameData: [],
      isNameDataFetched: true,
      nameDataErrorMessage: '',
    }));

    let endpoint = '';
    let searchPoint = '';

    // eslint-disable-next-line no-unused-expressions
    if (type === 'membership') {
      endpoint = 'plans';
      searchPoint = 'Tariff_GroupName';
    }

    if (type === 'product') {
      endpoint = 'products';
      searchPoint = 'Product_Tags';
    }

    if (type === 'resource') endpoint = 'resources';

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/nexudus-admin/${endpoint}?${searchPoint}=${category}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          },
        }
      )
      .then((res) => {
        set((state) => ({
          ...state,
          nameData: res.data,
          isNameDataFetched: false,
          nameDataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          nameData: [],
          isNameDataFetched: false,
          nameDataErrorMessage: error.message,
        }));
      });
  },
}));
