import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const useGetNexudusResourceData = create((set) => ({
  resourceNameData: [],
  isCategoryDataFetched: false,
  categoryDataErrorMessage: '',

  getResourceNames: (category) => {
    set((state) => ({
      ...state,
      resourceNameData: [],
      isResourceNameDataFetched: false,
      nameDataErrorMessage: '',
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/nexudus-admin/admin/resources`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
        params: { Resource_Name: category },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          resourceNameData: res.data,
          isResourceNameDataFetched: true,
          nameDataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          resourceNameData: [],
          isResourceNameDataFetched: false,
          nameDataErrorMessage: error.message,
        }));
      });
  },
}));
