import { Suspense } from 'react';
import { Spinner } from 'src/components/ui';
import { Routes, Route } from 'react-router-dom';
import NotFoundPage from './ErrorPages/404';
import { RequestsPage } from './RequestsPage';
import { InventoryPage } from './InventoryPage';
import { ContentPage } from './ContentPage';
import { LoginPage } from './Auth/LoginPage';
import { PromotionsPage } from './Promotions';

export const AppRouter = () => {
  return (
    <Suspense fallback="">
      <Spinner />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/requests" element={<RequestsPage />} />
        <Route path="/content" element={<ContentPage />} />
        <Route path="/inventory" element={<InventoryPage />} />
        <Route path="/promotions" element={<PromotionsPage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};
