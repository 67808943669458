import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { Box, TextField, Button } from 'src/components/ui';
import { useFormik, FormikProvider, Form, ErrorMessage } from 'formik';
import { FormControl } from '@mui/material';
import { useGetToken } from 'src/components/hooks';
import { useNavigate } from 'react-router';
import BrandLogo from 'src/components/assets/icons/brand.png';

export const LoginPage = () => {
  const { getToken, isAuth } = useGetToken();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      save: true,
      deviceId: 'string',
    },
    validateOnChange: false,
    validateOnBlur: false,
    // validationSchema, //

    onSubmit: async (e) => {
      await getToken(e);
      if (isAuth) {
        navigate('/requests');
      }
    },
  });

  useEffect(() => {
    if (!isAuth) return;
    navigate('/requests');
  }, [isAuth]);

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            marginTop: '200px',
            marginBottom: '20px',
            marginRight: '100px',
          }}
        >
          <img src={BrandLogo} alt="logo" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Box
          sx={{
            borderRadius: '12px',
            width: '400px',
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            marginRight: '100px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        >
          <Box sx={{ margin: '0 auto', fontSize: '24px', fontWeight: 'bold' }}>Login</Box>
          <FormikProvider value={formik}>
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
              }}
              onSubmit={formik.handleSubmit}
            >
              {/* username */}
              <FormControl fullWidth sx={{ marginTop: '24px' }}>
                <TextField
                  name="username"
                  label="Username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
                <ErrorMessage name="username" component="div" />
              </FormControl>

              {/* password */}
              <FormControl fullWidth sx={{ marginTop: '24px' }}>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                <ErrorMessage name="password" component="div" />
              </FormControl>
              <Button
                sx={{
                  marginTop: '48px',
                  fontSize: '24px',
                  background: '#1224FF',
                  color: 'white',
                }}
                type="submit"
              >
                Enter
              </Button>
            </Form>
          </FormikProvider>
        </Box>
      </Box>
    </Box>
  );
};
