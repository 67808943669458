import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const sync = (set) => {
  set((state) => ({
    ...state,
    data: '',
    dataErrorMessage: '',
  }));

  axiosInstance
    .post(`${process.env.REACT_APP_API_BASE_URL}/inventory/sync`)
    .then((res) => {
      console.log(res);
      set((state) => ({
        ...state,
        data: res.data.message,
        isDataFetching: false,
        dataErrorMessage: '',
      }));
    })
    .catch((error) => {
      console.error(error.message);
      set((state) => ({
        ...state,
        data: '',
        isDataFetching: false,
        dataErrorMessage: error.message,
      }));
    });
};

export const useSync = create((set) => ({
  data: '',
  isDataFetching: false,
  dataErrorMessage: '',
  sync: () => sync(set),
}));
