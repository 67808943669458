import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const useEditCategories = create((set) => ({
  isCategoriesEdited: false,
  dataErrorMessage: '',

  editCategories: (body, id) => {
    set((state) => ({
      ...state,
      isCategoriesEdited: false,
      dataErrorMessage: '',
    }));

    axiosInstance
      .patch(`${process.env.REACT_APP_API_BASE_URL}/categories/${id}`, body)
      .then(() => {
        set((state) => ({
          ...state,
          isCategoriesEdited: true,
          dataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          isCategoriesEdited: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
