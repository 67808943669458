import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';
import axiosInstance from '../auth/axiosInstance';

export const useGetRequests = create((set) => ({
  data: [],
  isDataFetching: false,
  dataErrorMessage: '',

  getRequests: (page, limit, searchObject) => {
    set((state) => ({
      ...state,
      data: [],
      isDataFetched: false,
      dataErrorMessage: '',
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/requests/admin`, {
        params: {
          page: page + 1,
          limit,
          ...searchObject,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          data: res.data,
          isDataFetched: true,
          dataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.log(error.response?.status);
        set((state) => ({
          ...state,
          data: [],
          isDataFetched: false,
          dataErrorMessage: error.message,
        }));
      });
  },
}));
